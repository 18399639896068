// supabaseClient.js
import { createClient } from '@supabase/supabase-js';

import { ANON_KEY, ANON_URL, DEV_ANON_KEY, DEV_ANON_URL } from '../../public/keys.js';

var supabaseUrl = '';
var supabaseAnonKey = '';

if (window.location.href.indexOf("fief") > -1) {
  supabaseUrl = ANON_URL;
  supabaseAnonKey = ANON_KEY;
}
else {
  supabaseUrl = DEV_ANON_URL;
  supabaseAnonKey = DEV_ANON_KEY;
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey);
