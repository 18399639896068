import { load } from 'ol/Image.js';
import { supabase } from '../supabaseClient.js';

const BattleHistoryPopupClose = document.getElementById('battle-history-popup-close');

BattleHistoryPopupClose.addEventListener('click', closeBattleHistoryPopup);

export function openBattleHistoryPopup(province) {

    const battleHistoryPopup = document.getElementById('battle-history-popup');

    loadBattleHistory(province);

    battleHistoryPopup.classList.remove('hidden');
}

export function closeBattleHistoryPopup() {
    const battleHistoryPopup = document.getElementById('battle-history-popup');
    battleHistoryPopup.classList.add('hidden');
}

export function loadBattleHistory(obj) {
	const battleHistoryList = document.getElementById('province-battle-history-list');
	battleHistoryList.innerHTML = '';

	obj.battle_history.forEach(battle => {

		battle.created_at += ' UTC';

		var local_time = new Date(battle.created_at).toLocaleString();

		const battleItem = document.createElement('li');
		battleItem.classList.add('battle-item');

		const battleInfo = document.createElement('div');
		battleInfo.innerHTML = `
		<strong>${battle.winner_nation_name}</strong> - ${local_time}<br>
		Province: ${battle.province}<br>
		${battle.nationA_name} Army Size: ${battle.groupA_total_size_before}<br>
		${battle.nationB_name} Army Size: ${battle.groupB_total_size_before}<br>
		${battle.nationA_name} Losses: ${battle.groupA_losses}<br>
		${battle.nationB_name} Losses: ${battle.groupB_losses}
		`;

		battleItem.appendChild(battleInfo);
		battleHistoryList.appendChild(battleItem);
	});
}
