import GeoJSON from 'ol/format/GeoJSON.js';
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import TileLayer from 'ol/layer/Tile.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';
import Cluster from 'ol/source/Cluster.js';
import XYZ from 'ol/source/XYZ.js';
import Feature from 'ol/Feature.js';
import { Point } from 'ol/geom.js';
import Overlay from 'ol/Overlay.js';
import { Style, Fill, Stroke, Text, Icon } from 'ol/style.js';
import { fromLonLat } from 'ol/proj.js';

// Import game data classes
import Settlement from '../settlements.js';
import Province from '../provinces.js';
import Army from '../armies.js';

// Import UI interaction functions
import { panelShowInfo } from '../../index.js';
import { showPopUp, closePopUp, showSettlementRadialMenu, hideSettlementRadialMenu, showProvinceRadialMenu, hideProvinceRadialMenu } from '../../ui/popup.js';
import { showRecruitPopup } from '../../ui/recruit_popup.js';
import { closeRightPanel } from '../../ui/rightpanel.js';
import { leftPanelSetInfo, closeLeftPanel } from '../../ui/leftpanel.js';
import { openNationInfoPopup, closeNationInfoPopup } from '../../ui/nation_popup.js'; // Import nation popup functions
import { openSettlementInfoPopup, closeSettlementInfoPopup } from '../../ui/settlement_popup.js'; // Import settlement popup functions
import { openBattleHistoryPopup, closeBattleHistoryPopup, loadBattleHistory } from '../../ui/battle_history_popup.js'; // Import battle history popup functions
import { loadOutliner } from '../../ui/outliner.js';

// Import map specific utility functions
import { drawMovementArrow } from './movement_arrows.js';
import { showClusterPopup } from './cluster_popup.js';
import { updateNationLabels, getNationLabelStyle } from './nation_labels.js';
import { promptFirstSettlementPlacement } from './settlement_placement.js';

// DOM element constants
const rightSlidingPanel = document.getElementById('right-sliding-panel');
const settlementCheckbox = document.getElementById('settlement-checkbox');
const armyCheckbox = document.getElementById('army-checkbox');

export default class MapObj {
  constructor() {
    // Game data stores
    this.settlements = {};
    this.provinces = {};
    this.armies = {};
    this.highlightedProvinces = [];
    this.armyLayers = [];

    // UI state
    this.RightPanel = '';
    this.firstSettlementPlacementRequired = false;
    this.settlementPlacementMode = false;
    this.cursorImage = null;
    this.mapSelectedArmyId = null;
    this.mapSelectedSettlementId = null;
    this.mapSelectedProvinceId = null;
    this.highlight = null;

    // Popup elements
    this.popupContainer = document.getElementById('popup');
    this.clusterPopupContainer = document.getElementById('cluster-popup');
    this.clusterPopupContent = document.getElementById('cluster-popup-content');
    this.clusterPopupCloser = document.getElementById('cluster-popup-closer');

    // Data sources
    this.armiesSource = new VectorSource();
    this.nationLabelsSource = new VectorSource();
    this.clusterSource = this.createClusterSource();

    // Vector layers
    this.settlementsLayer = this.createSettlementsLayer();
    this.armiesLayer = this.createArmiesLayer();
    this.provincesLayer = this.createProvincesLayer();
    this.backgroundLayer = this.createBackgroundLayer();
    this.nationLabelsLayer = this.createNationLabelsLayer();
    this.movementArrowsLayer = this.createMovementArrowsLayer();

    // Map instance
    this.map = this.createMap();

    // Overlays
    this.overlay = this.createPopupOverlay();
    this.clusterOverlay = this.createClusterPopupOverlay();
    this.settlementRadialMenuOverlay = this.createRadialMenuOverlay('settlement');
    this.provinceRadialMenuOverlay = this.createRadialMenuOverlay('province');
    this.recruitPopupOverlay = this.createRecruitPopupOverlay();

    // Add overlays and event listeners
    this.map.addOverlay(this.overlay);
    this.map.addOverlay(this.clusterOverlay);
    this.map.addOverlay(this.settlementRadialMenuOverlay);
    this.map.addOverlay(this.provinceRadialMenuOverlay);
    this.map.addOverlay(this.recruitPopupOverlay);
    this.setupEventListeners();
  }

  createClusterSource() {
    return new Cluster({
      distance: 50, // Pixel distance between points before clustering
      source: this.armiesSource,
    });
  }

  createSettlementsLayer() {
    return new VectorLayer({
      source: new VectorSource(),
      zIndex: 99,
    });
  }

  createArmiesLayer() {
    return new VectorLayer({
      source: this.clusterSource,
      style: this.getArmyClusterStyle.bind(this),
      zIndex: 100,
      visible: false,
    });
  }

  createProvincesLayer() {
    const defaultStyle = new Style({
      fill: new Fill({
        color: '#FAEBD7',
      }),
      stroke: new Stroke({
        color: '#404242',
        width: 1.5,
      }),
    });

    const dottedLineStyle = new Style({
      stroke: new Stroke({
        color: '#404242',
        width: 2,
        lineDash: [8, 4], // Dotted line pattern
      }),
    });

    return new VectorLayer({
      source: new VectorSource({
        url: 'map.geojson',
        format: new GeoJSON(),
      }),
      style: function (feature) {
        return feature.get('line') === 'true' ? dottedLineStyle : defaultStyle;
      },
    });
  }

  createBackgroundLayer() {
    return new TileLayer({
      source: new XYZ({
        url: 'ocean.jpg', // Replace with the path to your image file
      }),
      zIndex: 0,
    });
  }

  createNationLabelsLayer() {
    return new VectorLayer({
      source: this.nationLabelsSource,
      style: (feature, resolution) => getNationLabelStyle(this, feature, resolution),
      zIndex: 200,
      declutter: true,
    });
  }

  createMovementArrowsLayer() {
    return new VectorLayer({
      source: new VectorSource(),
      zIndex: 150,
    });
  }

  createMap() {
    return new Map({
      layers: [
        this.backgroundLayer,
        this.provincesLayer,
        this.nationLabelsLayer,
        this.settlementsLayer,
        this.armiesLayer,
        this.movementArrowsLayer,
      ],
      target: 'map',
      view: new View({
        center: fromLonLat([-0.1276, 51.5074]),
        zoom: 3,
      }),
    });
  }

  createPopupOverlay() {
    return new Overlay({
      element: this.popupContainer,
      autoPan: true,
      autoPanAnimation: {
        duration: 5,
      },
    });
  }

  createClusterPopupOverlay() {
    return new Overlay({
      element: this.clusterPopupContainer,
      autoPan: {
        animation: {
          duration: 150,
        }
      },
    });
  }

  createRadialMenuOverlay(type) {
    return new Overlay({
      element: document.getElementById(`${type}-radial-menu`),
      autoPan: false,
    });
  }

  createRecruitPopupOverlay() {
    const recruitPopupEl = document.getElementById('recruit-popup');
    return new Overlay({
      element: recruitPopupEl,
      autoPan: false,
    });
  }
  

  setupEventListeners() {
    this.map.on('click', this.onMapClick.bind(this));
    this.map.getView().on('change:resolution', this.onViewChangeResolution.bind(this));
    this.clusterPopupCloser.onclick = this.onClusterPopupClose.bind(this);
    this.map.getView().on('change:resolution', this.updateNationLabelVisibility.bind(this));
    document.getElementById('province-radial-recruit').addEventListener('click', () => showRecruitPopup());
    document.getElementById('settlement-radial-recruit').addEventListener('click', () => showRecruitPopup('settlement'));
    document.getElementById('province-radial-info').addEventListener('click', this.openNationInfoFromRadial.bind(this));
    document.getElementById('settlement-radial-info').addEventListener('click', this.openSettlementInfoFromRadial.bind(this));
    document.getElementById('province-radial-battle-history').addEventListener('click', this.openBattleHistoryFromRadial.bind(this));

    // In the constructor, after this.map is created:
  this.map.getViewport().addEventListener('contextmenu', (evt) => {
    // Prevent the browser's context menu
    evt.preventDefault();

    // Figure out which feature, if any, is under the mouse
    const pixel = this.map.getEventPixel(evt);
    const coordinate = this.map.getCoordinateFromPixel(pixel);
    const feature = this.map.forEachFeatureAtPixel(
      pixel,
      (feat) => feat,
      {
        hitTolerance: 5,
        layerFilter: (layer) =>
          this.armyLayers.includes(layer) ||
          layer === this.provincesLayer ||
          layer === this.settlementsLayer,
      }
    );
    
    // Pass the feature and coordinate to our new right-click handler
    this.handleRightClick(feature, coordinate);
  });

}
  

  onClusterPopupClose() {
    window.map.mergeMode = false;
    document.getElementById('cluster-merge-icon').src = 'merge.png';
    this.clusterPopupContainer.classList.add('hidden');
    this.clusterOverlay.setPosition(undefined);
    return false;
  }

  updateNationLabelVisibility() {
    const zoom = this.map.getView().getZoom();
    const nationLabelsVisible = zoom < 5; // Adjust the zoom level threshold as needed
    this.nationLabelsLayer.setVisible(nationLabelsVisible);
  }

  // Layer Visibility and Management
  toggleArmyLayers(visible) {
    this.armyLayers.forEach((layer) => layer.setVisible(visible));
  }

  onViewChangeResolution() {
    const zoom = this.map.getView().getZoom();
    const armyLayersVisible = zoom >= 5 && armyCheckbox.checked; // Adjust the zoom level threshold as needed
    this.armyLayers.forEach((layer) => layer.setVisible(armyLayersVisible));

    const settlementLayerVisible = zoom >= 5 && settlementCheckbox.checked; // Adjust the zoom level threshold as needed
    this.settlementsLayer.setVisible(settlementLayerVisible);

    this.updateNationLabelVisibility();
  }

  // Styling Functions
  getArmyClusterStyle(feature) {
    const features = feature.get('features') || [];
    const size = features.length;
    const containsSelectedArmy = features.some((f) => f.get('army_id') === this.mapSelectedArmyId);
    // Check if any feature in the cluster is pinned or potentially pinned
    const anyPinned = features.some((f) => f.get('pinned'));
    const anyPotentiallyPinned = features.some((f) => f.get('potentially_pinned'));
    
    // Get the pin reason if any army is pinned
    const pinnedArmy = features.find(f => f.get('pinned'));
    const pinReason = pinnedArmy ? this.armies[pinnedArmy.get('army_id')]?.pinReason : null;
  
    let baseStyle;
  
    if (!containsSelectedArmy && size > 1) {
      const totalArmySize = features.reduce((sum, f) => sum + (f.get('size') || 0), 0);
      baseStyle = new Style({
        image: new Icon({
          src: 'army_icon2.png',
          anchor: [0.5, 1.2],
          scale: 0.13,
        }),
        text: new Text({
          text: `${totalArmySize}K`,
          fill: new Fill({ color: '#fff' }),
          font: 'bold 16px Lucida Handwriting',
        }),
      });
    } else {
      const armyFeature = features.find((f) => f.get('army_id') === this.mapSelectedArmyId);
      if (armyFeature) {
        const armySize = armyFeature.get('size') || 0;
        baseStyle = new Style({
          image: new Icon({
            src: 'army_icon.png',
            anchor: [0.5, 1.2],
            scale: 0.13,
          }),
          text: new Text({
            text: `${armySize}K`,
            fill: new Fill({ color: '#fff' }),
            font: 'bold 17px Lucida Handwriting',
            stroke: new Stroke({ color: '#000', width: 3 }),
          }),
        });
      } else if (size === 1) {
        const singleArmy = features[0];
        const armySize = singleArmy.get('size') || 0;
        baseStyle = new Style({
          image: new Icon({
            src: 'army_icon.png',
            anchor: [0.5, 1.2],
            scale: 0.13,
          }),
          text: new Text({
            text: `${armySize}K`,
            fill: new Fill({ color: '#fff' }),
            font: 'bold 16px Lucida Handwriting',
          }),
        });
      } else {
        const totalArmySize = features.reduce((sum, f) => sum + (f.get('size') || 0), 0);
        baseStyle = new Style({
          image: new Icon({
            src: 'army_icon2.png',
            anchor: [0.5, 1.2],
            scale: 0.13,
          }),
          text: new Text({
            text: `${totalArmySize}K`,
            fill: new Fill({ color: '#fff' }),
            font: 'bold 16px Lucida Handwriting',
          }),
        });
      }
    }

    const styles = [baseStyle];
  
    // Add pin icon for pinned armies
    if (anyPinned) {
      styles.push(new Style({
        image: new Icon({
          src: 'pin_icon.png',
          anchor: [-0.8, 6.8],
          scale: .03,
        }),
              }));
    }
    
    // Add a different visual indicator for potentially pinned armies
    if (anyPotentiallyPinned) {
      styles.push(new Style({
        image: new Icon({
          src: 'dice.png',
          anchor: [-0.95, 8.5],
          scale: .02,
        }),
              }));
    }
  
    return styles;
  }

  highlightProvince(province, options = {}) {
    const feature = this.provincesLayer.getSource().getFeatures().find((f) => f.get('id') == province.id);
    if (feature && feature.get('line') != 'true') {
      const highlightStyle = new Style({
        fill: options.isMoving
          ? new Fill({
              color: 'rgba(85, 189, 79)', // Different fill for selected state
            })
          : feature.getStyle()?.getFill() || new Fill({ color: '#FAEBD7' }),
        stroke: new Stroke({
          color: options.isSelected ? '#464747' : '#185419', // Different stroke color for selected state
          width: 4,
          lineDash: options.isSelected ? undefined : [8, 8],
        }),
        text: options.isMoving
          ? new Text({
              text: 'Move',
              font: 'bold 16px Lucida Handwriting',
            })
          : null,
        zIndex: 5,
      });

      feature.setStyle(highlightStyle);
    }
  }

  resetProvinceStyle(province) {
    const feature = this.provincesLayer.getSource().getFeatures().find((f) => f.get('id') == province.id);
    if (feature) {
      const highlightStyle = new Style({
        fill: province.color ? new Fill({ color: province.color }) : new Fill({ color: '#FAEBD7' }),
        stroke: new Stroke({
          color: '#404242',
          width: 1.5,
          lineDash: undefined,
        }),
        text: null,
      });
      feature.setStyle(highlightStyle);
    }
  }

  // Map Interaction Handling
  onMapClick(evt) {
    this.featureClick(evt.pixel, evt.coordinate);
  }

  featureClick(pixel, coordinate) {
    const feature = this.map.forEachFeatureAtPixel(
      pixel,
      (feature) => feature,
      {
        hitTolerance: 5,
        layerFilter: (layer) =>
          this.armyLayers.includes(layer) ||
          layer === this.provincesLayer ||
          layer === this.settlementsLayer,
      }
    );

    if (this.movingArmy) {
      this.handleMoveModeClick(feature);
    } else if (feature) {
      this.handleRegularClick(feature, coordinate);
    } else {
      this.handleMapBackgroundClick();
    }
  }

  handleMoveModeClick(feature) {
    if (feature && this.featureInLayer(feature, this.provincesLayer)) {
      this.handleMoveToProvince(feature);
    } else if (feature && this.featureInLayer(feature, this.settlementsLayer)) {
      this.handleMoveToSettlement(feature);
    } else {
      alert('Please select a valid province or settlement.');
    }
  }

  handleMoveToProvince(feature) {
    const province = this.provinces[feature.get('id')];
    drawMovementArrow(this, this.movingArmy, { id: province.id, type: 'province' });
    this.selectedMoveDestination = { location_id: province.id, location_type: 'province' };
    this.movingArmy.moveArmy(province.id, 'province');
    this.clearMoveHighlights();
    this.movingArmy = null;
  }

  handleMoveToSettlement(feature) {
    const settlement = this.settlements[feature.get('id')];
    drawMovementArrow(this, this.movingArmy, { id: settlement.id, type: 'settlement' });
    this.selectedMoveDestination = { location_id: settlement.id, location_type: 'settlement' };
    this.movingArmy.moveArmy(settlement.id, 'settlement');
    this.clearMoveHighlights();
    this.resetProvinceStyle(this.provinces[settlement.province_id]);
    this.movingArmy = null;
  }

  handleRegularClick(feature, coordinate) {
    if (this.featureInLayer(feature, this.provincesLayer) && feature.get('line') != 'true') {
      this.handleProvinceClick(feature, coordinate);
    } else if (this.featureInLayer(feature, this.settlementsLayer)) {
      this.handleSettlementClick(feature, coordinate);
    } else if (feature.get('features')) {
      this.handleClusterClick(feature, coordinate);
    }
  }

  // In your MapObj class, just fill in the handleRightClick method:
handleRightClick(feature) {
  // Only proceed if we have a currently selected army
  if (!this.mapSelectedArmyId) {
    return;
  }
  const selectedArmy = this.armies[this.mapSelectedArmyId];
  if (!selectedArmy) {
    return;
  }

  // Check if user right-clicked on a province (and not on the dotted-line border)
  if (this.featureInLayer(feature, this.provincesLayer) && feature.get('line') !== 'true') {
    const province = this.provinces[feature.get('id')];
    // Move the army
    this.clearMovementArrows();
    drawMovementArrow(this, selectedArmy, { id: province.id, type: 'province' });
    selectedArmy.moveArmy(province.id, 'province');

    // Clear move highlights and reset any needed styling
    this.clearMoveHighlights();
    this.movingArmy = null;

  } else if (this.featureInLayer(feature, this.settlementsLayer)) {
    // If user right-clicked on a settlement
    const settlement = this.settlements[feature.get('id')];
    drawMovementArrow(this, selectedArmy, { id: settlement.id, type: 'settlement' });
    selectedArmy.moveArmy(settlement.id, 'settlement');

    // Clear move highlights and reset any needed styling
    this.clearMoveHighlights();
    this.movingArmy = null;

  } else {
    // Right-click not on a valid province or settlement
    alert("Please right-click on a valid province or settlement to move your army.");
  }
}


  handleProvinceClick(feature, coordinate) {
    this.mapSelectedProvinceId = feature.get('id');
    hideProvinceRadialMenu();
    hideSettlementRadialMenu();
    const province = this.provinces[feature.get('id')];
    this.highlightSelectedProvince(feature, province);
    this.provinceRadialMenuOverlay.setPosition(coordinate);
    showProvinceRadialMenu();
    loadBattleHistory(province);
  }

  handleSettlementClick(feature, coordinate) {
    hideProvinceRadialMenu();
    hideSettlementRadialMenu();
    const settlement = this.settlements[feature.get('id')];
    this.mapSelectedSettlementId = settlement.id;
    this.settlementRadialMenuOverlay.setPosition(coordinate);
    showSettlementRadialMenu();

  }

  handleClusterClick(feature, coordinate) {
    showClusterPopup(this, feature, coordinate);
    const featuresInCluster = feature.get('features');
    const userArmies = featuresInCluster.filter((f) => f.get('user_id') == window.currentUser.id);

    if (userArmies.length > 0) {
      this.handleUserArmiesInCluster(feature, coordinate, userArmies);
    } else {
      console.log('No user armies in this cluster.');
    }
  }

  handleUserArmiesInCluster(feature, coordinate, userArmies) {
    this.updateSelectedClusterFeature(feature);
    const selectedArmyFeature = this.selectNextArmyInCluster(feature, userArmies);

    if (selectedArmyFeature) {
      showClusterPopup(this, feature, coordinate);
      //this.openRightPanelForArmyLocation(selectedArmyFeature);
    }
  }

  updateSelectedClusterFeature(feature) {
    if (this.selectedClusterFeature && this.selectedClusterFeature !== feature) {
      this.selectedClusterFeature.set('selectedUserArmyIndex', null);
      this.selectedClusterFeature = null;
    }
    if (!feature.get('selectedUserArmyIndex') && feature.get('selectedUserArmyIndex') !== 0) {
      feature.set('selectedUserArmyIndex', 0);
      this.selectedClusterFeature = feature;
    } else {
      let currentIndex = feature.get('selectedUserArmyIndex');
      currentIndex++;
      if (currentIndex >= feature.get('features').filter(f => f.get('user_id') == window.currentUser.id).length) {
        currentIndex = 0;
      }
      feature.set('selectedUserArmyIndex', currentIndex);
      this.selectedClusterFeature = feature;
    }
    feature.changed();
  }

  selectNextArmyInCluster(feature, userArmies) {
    const currentIndex = feature.get('selectedUserArmyIndex');
    return userArmies[currentIndex];
  }

  openRightPanelForArmyLocation(armyFeature) {
    const location_type = armyFeature.get('location_type');
    if (location_type == 'province') {
      rightSlidingPanel.classList.add('open');
      panelShowInfo(rightSlidingPanel, 'province');
      closePopUp();
    }
    closePopUp();
  }

    // In /js/classes/map/mapobj.js

    // In /js/classes/map/mapobj.js
    openNationInfoFromRadial() {
      hideProvinceRadialMenu();
      const province = this.provinces[this.mapSelectedProvinceId];
      if (province && province.nation_id) {
        const nation = {
          id: province.nation_id, // Pass the nation ID
          name: province.nation_name,
          ruler: province.nation_user,
          provinces: Object.values(this.provinces).filter(p => p.nation_id === province.nation_id).length,
        };
        openNationInfoPopup(nation);
      }
    }

  openSettlementInfoFromRadial() {
    hideSettlementRadialMenu();
    const settlement = this.settlements[this.mapSelectedSettlementId];
    if (settlement) {
      openSettlementInfoPopup(settlement);
    }
  }

  openBattleHistoryFromRadial() {
    const province = this.provinces[this.mapSelectedProvinceId];
    if (province) {
      openBattleHistoryPopup(province);
    }
  }

  handleMapBackgroundClick() {
    this.overlay.setPosition(undefined);
    this.resetHighlightedProvince();
    this.resetSelectedCluster();
    closePopUp();
    // closeLeftPanel();
    // closeRightPanel();
    this.refreshArmyLayers();
    this.clearMovementArrows();
    window.map.mergeMode = false;
    document.getElementById('cluster-merge-icon').src = 'merge.png';
    // Hide radial menu
    hideProvinceRadialMenu();
    hideSettlementRadialMenu();

    closeNationInfoPopup();
    closeSettlementInfoPopup();
    closeBattleHistoryPopup();
  }

  highlightSelectedProvince(feature, province) {
    if (this.highlight && this.highlight !== feature) {
      this.resetProvinceStyle(this.provinces[this.highlight.get('id')]);
    }
    this.highlight = feature;
    this.highlightProvince(province, { isSelected: true });
    this.map.render();
  }

  resetHighlightedProvince() {
    if (this.highlight) {
      this.resetProvinceStyle(this.provinces[this.highlight.get('id')]);
      this.highlight = null;
    }
  }

  resetSelectedCluster() {

    this.clusterPopupContainer = document.getElementById('cluster-popup');

    // if (this.selectedClusterFeature || this.mapSelectedArmyId) {
      this.selectedClusterFeature?.set('selectedUserArmyIndex', null);
      this.selectedClusterFeature?.changed();
      this.selectedClusterFeature = null;
      this.mapSelectedArmyId = null;
      this.clusterPopupContainer.classList.add('hidden');
      this.clusterOverlay.setPosition(undefined);
    // }
  }

  refreshArmyLayers() {
    this.armyLayers.forEach((layer) => layer.changed());
  }

  clearMovementArrows() {
    this.movementArrowsLayer.getSource().clear();
  }

  clearMoveHighlights() {
    for (const provinceId of this.highlightedProvinces) {
      this.resetProvinceStyle(this.provinces[provinceId]);
    }
    this.highlightedProvinces = [];
  }

  // Movement Logic
  enterMoveMode(army) {
    this.movingArmy = army;

    if (army.location_type == 'settlement') {
      this.highlightMovableProvincesForSettlement(army);
    } else {
      this.highlightMovableProvincesForArmy(army);
    }
  }

  highlightMovableProvincesForSettlement(army) {
    const settlement = this.settlements[army.location];
    const province = this.provinces[settlement.province_id];
    this.highlightedProvinces.push(province.id);
    this.highlightProvince(province, { isMoving: true });
  }

  highlightMovableProvincesForArmy(army) {
    const province = this.provinces[army.location];
    const feature = this.provincesLayer.getSource().getFeatures().find((f) => f.get('id') == province.id);
    feature.getStyle().setZIndex(99);

    const borderProvinces = this.provinces[army.location].borderProvinces;
    for (const borderProvince of borderProvinces) {
      this.highlightedProvinces.push(borderProvince);
      this.highlightProvince(this.provinces[borderProvince], { isMoving: true });
    }
  }

  // Data Loading and Initialization
  async loadProvinces() {
    try {
      const response = await fetch('api/get_provinces');
      const provinceData = await response.json();
      this.provinces = {};

      provinceData.forEach((province) => {
        const workingProvince = new Province(province, this);
        this.provinces[province.id] = workingProvince;
        workingProvince.loadProvinceData();
        workingProvince.nation_id = province.nation_id;
        workingProvince.nation_name = province.nation_name;
      });
      updateNationLabels(this);
    } catch (error) {
      console.error('Error loading provinces:', error);
    }
  }

  async loadSettlements() {
    try {
      const response = await fetch('api/get_settlements');
      const settlementData = await response.json();
      this.settlements = {};

      //clear the settlements layer
      this.settlementsLayer.getSource().clear();

      settlementData.forEach((settlement) => {
        const workingSettlement = new Settlement(settlement, this);
        this.settlements[settlement.id] = workingSettlement;
        workingSettlement.drawIcon();
      });

      if (!window.currentUser) {
        console.log('User not logged in.');
        return;
      }

      const userSettlement = settlementData.find((settlement) => settlement.user_id == window.currentUser.id);

      if (!userSettlement) {
        this.firstSettlementPlacementRequired = true;
        promptFirstSettlementPlacement(this);
      } else {
        await window.currentUser;
      }
    } catch (error) {
      console.error('Error loading settlements:', error);
    }
  }

  async loadArmies() {
    try {
      const response = await fetch('api/get_armies');
      const armyData = await response.json();
      this.armies = {};

      armyData.forEach((army) => {
        const workingArmy = new Army(army, this);
        this.armies[army.id] = workingArmy;
      });

      this.computeArmyConflictStatus();

      const armiesByNation = armyData.reduce((acc, army) => {
        if (this.armies[army.id].isPinned) {
          army.isPinned = true;
        }
        if (this.armies[army.id].isPotentiallyPinned) {
          army.isPotentiallyPinned = true;
        }
        if (army.location_type === 'province') {
          acc[army.nation_id] = acc[army.nation_id] || [];
          acc[army.nation_id].push(army);
        }
        return acc;
      }, {});

      this.armyLayers.forEach((layer) => this.map.removeLayer(layer));
      this.armyLayers = [];


      Object.keys(armiesByNation).forEach((nationId) => {
        this.createArmyLayerForNation(nationId, armiesByNation[nationId]);
      });

      
    } catch (error) {
      console.error('Error loading armies:', error);
    }
  }

  async reLoadData() {
    console.log('Reloading data...');
    await Promise.all([
        this.loadProvinces(),
        this.loadSettlements(),
        this.loadArmies()
      ]).then(() => {
        if (window.currentUser) {
          loadOutliner()
        }
      });
    console.log('Data reloaded.');
  }

  createArmyLayerForNation(nationId, nationArmies) {
    const armySource = new VectorSource();
    const nationClusterSource = new Cluster({
      distance: 50, // Distance for clustering
      source: armySource,
    });

    const armyFeatures = nationArmies
      .map((army) => this.createArmyFeature(army))
      .filter((feature) => feature !== null);

    armySource.addFeatures(armyFeatures);

    const armyLayer = new VectorLayer({
      source: nationClusterSource,
      style: this.getArmyClusterStyle.bind(this),
      zIndex: 100,
      visible: false,
      renderMode: 'vector',
    });
    this.armyLayers.push(armyLayer);
    this.map.addLayer(armyLayer);
    this.armyLayers.forEach((layer) => layer.setVisible(true));
  }

  createArmyFeature(army) {
    const province = this.provinces[army.location];
    if (!province) {
      console.warn(`Province not found for army in province ${army.location}`);
      return null;
    }
    const provinceFeature = this.provincesLayer.getSource().getFeatures().find((f) => f.get('id') == province.id);
    if (!provinceFeature) {
      console.warn(`Feature not found for province ${province.id}`);
      return null;
    }
    const provinceCenter = provinceFeature.getGeometry().getInteriorPoint().getCoordinates();
    const armyFeature = new Feature({
      geometry: new Point(provinceCenter),
      size: army.size,
      nation_id: army.nation_id,
      nation_color: army.nation_color,
    });
    armyFeature.set('user_id', army.user_id);
    armyFeature.set('location', army.location);
    armyFeature.set('location_type', army.location_type);
    armyFeature.set('army_id', army.id);
    armyFeature.set('allegiance', army.allegiance);
    armyFeature.set('destination', army.destination);
    if (army.isPinned) {
      armyFeature.set('pinned', true);
    }
    if (army.isPotentiallyPinned) {
      armyFeature.set('potentially_pinned', true);
    }
    return armyFeature;
  }

  setMapCenter() {
    const userSettlementKey = Object.keys(this.settlements).find(
      (key) => this.settlements[key].user_id == window.currentUser.id
    );

    if (userSettlementKey) {
      const userCenter = this.settlements[userSettlementKey].coords;
      const view = this.map.getView();
      view.setCenter(fromLonLat(userCenter));
      view.setZoom(6);
    }
  }

  // Utility Functions
  featureInLayer(feature, layer) {
    return layer.getSource().getFeatures().includes(feature);
  }

  getFeatureOffset(feature, size) {
    const angle = (Math.PI * 2 * Math.random()) / size;
    const distance = 500;
    const offsetX = Math.cos(angle) * distance;
    const offsetY = Math.sin(angle) * distance;
    return [offsetX, offsetY];
  }

  areEnemies(armyA, armyB) {
    if (armyA.nation_id === armyB.nation_id) {
      // Disloyal armies fight loyal armies from the same nation.
      if (armyA.loyal !== armyB.loyal) {
        return true;
      }
      return false;
    } else {
      // Loyal armies from different nations fight (and disloyal armies don’t engage enemies).
      if (!armyA.loyal || !armyB.loyal) {
        return false;
      }
      return true;
    }
  }

  // Helper function to check if provinces are bordering
  provincesAreBordering(provinceA, provinceB) {
    return this.provinces[provinceA]?.borderProvinces?.includes(provinceB) ||
           this.provinces[provinceB]?.borderProvinces?.includes(provinceA);
  }

  computeArmyConflictStatus() {
    const armiesArray = Object.values(this.armies);
    const pinnedArmies = new Set();
    const potentialPinArmies = new Set();

    //loop through all armies and get their locations
    const armyLocations = armiesArray.reduce((acc, army) => {
      if (!acc[army.location]) {
        acc[army.location] = [];
      }
      acc[army.location].push(army);
      return acc;
    }, {});

    //loop through army locations and gather any armies that have it as a destination
    for (const location in armyLocations) {
      const armiesAtLocation = armyLocations[location];
      const armiesMovingToLocation = armiesArray.filter(army => army.destination == location && this.areEnemies(army, armiesAtLocation[0]));

      //if the total size of the armies at the location is less than the total size of the armies moving to it, pin them
      const totalSizeAtLocation = armiesAtLocation.reduce((sum, army) => sum + army.size, 0);
      const totalMovingToLocation = armiesMovingToLocation.reduce((sum, army) => sum + army.size, 0);

      if (totalSizeAtLocation < totalMovingToLocation) {
        armiesAtLocation.forEach(army => pinnedArmies.add(army.id));

        //set army.pinnedBy to an array of the armies moving to the location
        armiesAtLocation.forEach(army => {
          army.pinnedBy = armiesMovingToLocation.filter(movingArmy => this.areEnemies(army, movingArmy));
        });

        //set army.pinSize to the total size of the armies moving to the location
        armiesAtLocation.forEach(army => {
          army.pinSize = totalMovingToLocation;
        });
        
      }
    }


    //loop through army locations again and gather any armies that are pinned
    for (const location in armyLocations) {
      const armiesAtLocation = armyLocations[location];
      const pinnedArmiesAtLocation = armiesAtLocation.filter(army => pinnedArmies.has(army.id));
      const armiesMovingToLocation = armiesArray.filter(army => army.destination == location);

      //if there are pinned armies at the location, check if any of the armies moving to the location are the ones pinning it using (pinnedBy)
      if (pinnedArmiesAtLocation.length > 0) {
        armiesMovingToLocation.forEach(army => {
          if (pinnedArmiesAtLocation.some(pinnedArmy => pinnedArmy.pinnedBy.includes(army))) {
            //loop through pinned armies
            pinnedArmiesAtLocation.forEach(pinnedArmy => {
              if (pinnedArmy.pinSize <= army.pinSize) {
                //remove pinned army from pinnedArmies
                pinnedArmies.delete(pinnedArmy.id);
              }
            });
          }
        });
      }
    }


    // // Process border interception scenarios
    for (const army of armiesArray) {
      if (!army.destination || pinnedArmies.has(army.id)) continue;

      // Only check armies moving to empty provinces
      const destHasEnemies = armiesArray.some(a => 
        a.location == army.destination && 
        //!pinnedArmies.has(a.id) && 
        this.areEnemies(army, a)
      );

      // console.log(`Army ${army.id} moving to ${army.destination}, has enemies: ${destHasEnemies}`);

      if (!destHasEnemies) {
        // Check for enemy armies in bordering provinces that are moving toward this army's current location
        const interceptingArmy = armiesArray.find(enemyArmy => 
          !pinnedArmies.has(enemyArmy.id) &&
          this.areEnemies(army, enemyArmy) &&
          enemyArmy.destination == army.location &&
          this.provincesAreBordering(enemyArmy.location, army.destination)
        );

        if (interceptingArmy) {
          pinnedArmies.add(army.id);
          army.pinReason = `Intercepted by ${interceptingArmy.size}K army from ${this.provinces[interceptingArmy.location].name}`;
          continue;
        }
      }
    }

    
    //process head on conflicts
    for (const location in armyLocations) {
      const armiesAtLocation = armyLocations[location];
      const armiesMovingToLocation = armiesArray.filter(army => army.destination == location && this.areEnemies(army, armiesAtLocation[0]));
      let attackersFromMultipleProvinces = true;

      //determine if armies are coming from multiple provinces
      const provincesOfOrigin = new Set(armiesMovingToLocation.map(army => army.location));
      if (provincesOfOrigin.size > 1) {
        continue;
      }
      else {
        attackersFromMultipleProvinces = false;
        //if the size of the armies at the location is equal to the size of the armies moving to it, set all armies as potential pin
        const totalSizeAtLocation = armiesAtLocation.reduce((sum, army) => sum + army.size, 0);
        const totalMovingToLocation = armiesMovingToLocation.reduce((sum, army) => sum + army.size, 0);
        if (totalSizeAtLocation == totalMovingToLocation) {
          // make sure all armies at the location have the same destination
          const destinations = new Set(armiesAtLocation.map(army => army.destination));
          if (destinations.size == 1) {
            //make sure the destination is the same as the location of the armies moving to the location
            if (armiesAtLocation[0].destination == armiesMovingToLocation[0].location) {
              armiesAtLocation.forEach(army => potentialPinArmies.add(army.id));
              armiesMovingToLocation.forEach(army => potentialPinArmies.add(army.id));
            }
            
          }

        }
      }

    
    }


    //Update army statuses
    armiesArray.forEach(army => {
      army.isPinned = pinnedArmies.has(army.id);
      army.isPotentiallyPinned = potentialPinArmies.has(army.id);
    
    });
  }

  async focusArmy(armyId) {
    if (window.map.armies[armyId].location_type == 'province') {
      const clusterFeature = this.armyLayers.flatMap(layer =>
        layer.getSource().getFeatures()
      ).find(f =>
        f.get('features') && f.get('features').some(f2 => f2.get('army_id') == armyId)
      );
      if (!clusterFeature) return;
      const coordinate = clusterFeature.getGeometry().getCoordinates();
      this.updateSelectedClusterFeature(clusterFeature);
      await new Promise(resolve => {
        //if on mobile, hide the outliner
        if (window.innerWidth < 768) {
          //collapse outliner
          const outliner = document.getElementById('outliner');
          outliner.classList.add('outliner-collapsed');
        }

        this.map.getView().animate({ center: coordinate, duration: 300 }, resolve);
        this.map.getView().animate({ zoom: 7, duration: 300 }, resolve);
      });

      var updatedClusterFeature = this.armyLayers.flatMap(layer =>
        layer.getSource().getFeatures()
      ).find(f =>
        f.get('features') && f.get('features').some(f2 => f2.get('army_id') == armyId)
      );

      var updatedCoordinate = updatedClusterFeature.getGeometry().getCoordinates();

      showClusterPopup(this, updatedClusterFeature, updatedCoordinate);
    }
    else {
      //get settlement OL feature
      const feature = this.settlementsLayer.getSource().getFeatures().find((f) => f.get('id') == window.map.armies[armyId].location);
      const coordinate = feature.getGeometry().getCoordinates();
      await new Promise(resolve => {
        //if on mobile, hide the outliner
        if (window.innerWidth < 768) {
          //collapse outliner
          const outliner = document.getElementById('outliner');
          outliner.classList.add('outliner-collapsed');
        }
        this.map.getView().animate({ center: coordinate, duration: 300 }, resolve);
      });

      //get armies in settlement
      const armiesInSettlement = Object.values(window.map.armies).filter((army) => army.location == feature.get('id') && army.location_type == 'settlement');
      showClusterPopup(this, armiesInSettlement, coordinate);
    }

  }

  async focusSettlement(settlement_id) {
    //if on mobile, hide the outliner
    if (window.innerWidth < 768) {
      //collapse outliner
      const outliner = document.getElementById('outliner');
      outliner.classList.add('outliner-collapsed');
    }

    const feature = this.settlementsLayer.getSource().getFeatures().find((f) => f.get('id') == settlement_id);
    const coordinate = feature.getGeometry().getCoordinates();
    await new Promise(resolve => {
      this.map.getView().animate({ center: coordinate, duration: 300 }, resolve);
      this.map.getView().animate({ zoom: 8, duration: 300 }, resolve);
    });


  }
}