import { showClusterPopup } from '../classes/map/cluster_popup.js';

const popupContainer = document.getElementById('popup');
const popupProvinceContainer = document.getElementById('popup-province');
const popupProvince = document.getElementById('popup-province-id');
const popupProvinceOwner = document.getElementById('popup-province-owner-id');
const popupCloser = document.getElementById('popup-closer');
const popupSettlementContainer = document.getElementById('popup-settlement');
const popupSettlement = document.getElementById('popup-settlement-name')
const popupSettlementOwner = document.getElementById('popup-settlement-owner-id');

function popupShowInfo(type) {
	if (type === 'province') {
		popupProvinceContainer.style.display = 'block';
		popupSettlementContainer.style.display = 'none';
	} 
	else if (type === 'settlement') {
		popupProvinceContainer.style.display = 'none';
		popupSettlementContainer.style.display = 'block';
	}
}

export function showPopUp(type, data, coordinate) {
	if (type == 'Settlement') {
		popupSettlement.innerHTML = data.name;
		popupSettlementOwner.innerHTML = data.user_display_name;
		popupContainer.classList.add('open');
		popupShowInfo('settlement')
		window.map.overlay.setPosition(coordinate);
	}
	else if (type == 'Province') {
		popupProvince.innerHTML = data.id;
		popupProvinceOwner.innerHTML = data.nation_id;
		popupContainer.classList.add('open');
		popupShowInfo('province')
		window.map.overlay.setPosition(coordinate);
	}
	
}

popupCloser.onclick = () => {
	window.map.overlay.setPosition(undefined);
	popupCloser.blur();
	return false;
};

export function closePopUp() {
	popupContainer.classList.remove('open');
	window.map.overlay.setPosition(undefined);
	popupCloser.blur();
	return false;
}

export function showSettlementRadialMenu() {
	const menu = document.getElementById('settlement-radial-menu');
	menu.classList.remove('hidden');
	menu.classList.add('open');
	// Removed manual menu.style.left / top lines

	var settlement_id = window.map.mapSelectedSettlementId;

	//if not current user's settlement
	if (window.map.settlements[settlement_id].user_id != window.currentUser.id) {
		document.getElementById('settlement-radial-recruit').style.display = 'none';
	}
	else {
		document.getElementById('settlement-radial-recruit').style.display = 'block';
	}
}

export function hideSettlementRadialMenu() {
	const menu = document.getElementById('settlement-radial-menu');
	menu.classList.remove('open');
	menu.classList.add('hidden');
}


export function showProvinceRadialMenu() {
	var province_id = window.map.mapSelectedProvinceId;
	
	if (!window.map.provinces[province_id].nation_id) {
		return;
	}
	
	//if not current user's province
	if (window.map.provinces[province_id].nation_user_id != window.currentUser.id) {
		document.getElementById('province-radial-recruit').style.display = 'none';
	}
	else {
		document.getElementById('province-radial-recruit').style.display = 'block';
	}

	const menu = document.getElementById('province-radial-menu');
	menu.classList.remove('hidden');
	menu.classList.add('open');
	// Removed manual menu.style.left / top lines
  }
  
  export function hideProvinceRadialMenu() {
	const menu = document.getElementById('province-radial-menu');
	menu.classList.remove('open');
	menu.classList.add('hidden');
  }


//add event listener for the army radial button
document.getElementById('settlement-radial-army').addEventListener('click', () => {

  var settlement_id = window.map.mapSelectedSettlementId;

  //get settlement OL feature
  const feature = window.map.settlementsLayer.getSource().getFeatures().find((f) => f.get('id') == settlement_id);


  const armiesInSettlement = Object.values(window.map.armies).filter((army) => army.location == settlement_id && army.location_type == 'settlement');
  showClusterPopup(window.map, armiesInSettlement, feature.getGeometry().getCoordinates());

});

