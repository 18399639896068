import { supabase } from '../supabaseClient.js';

const nationInfoPopup = document.getElementById('nation-info-popup');
const nationInfoPopupClose = document.getElementById('nation-info-popup-close');
const nationInfoPopupTitle = document.getElementById('nation-info-popup-title');
const nationInfoRuler = document.getElementById('nation-info-ruler');
const nationInfoProvinces = document.getElementById('nation-info-provinces');
const btnChangeNationName = document.getElementById('btn-change-nation-name');

// Economy tab elements
const nationEconomyMoneyProvinces = document.getElementById('nation-economy-money-provinces');
const nationEconomyMoneyLoyalSettlements = document.getElementById('nation-economy-money-loyal-settlements');
const nationEconomyMoneyMaintenance = document.getElementById('nation-economy-money-maintenance');
const nationEconomyMoneyNet = document.getElementById('nation-economy-money-net');
const nationEconomyManpowerProvinces = document.getElementById('nation-economy-manpower-provinces');
const nationEconomyManpowerLoyalSettlements = document.getElementById('nation-economy-manpower-loyal-settlements');
const nationEconomyManpowerNet = document.getElementById('nation-economy-manpower-net');

const infoPopupTabs = document.querySelectorAll('.info-popup-tab');
const infoPopupTabContents = document.querySelectorAll('.info-popup-tab-content');

let isEditingNationName = false;

nationInfoPopupClose.addEventListener('click', closeNationInfoPopup);

infoPopupTabs.forEach(tab => {
    tab.addEventListener('click', async () => {
        const target = tab.dataset.tab;

        // Deactivate all tabs and content
        infoPopupTabs.forEach(t => t.classList.remove('active'));
        infoPopupTabContents.forEach(content => content.classList.remove('active'));

        // Activate the clicked tab
        tab.classList.add('active');

        // Activate the corresponding content
        const targetContent = document.getElementById(target);
        if (targetContent) {
            targetContent.classList.add('active');

            // Fetch economy data ONLY if the Economy tab is clicked and data hasn't been fetched yet
            if (target === 'nation-info-economy' && nationInfoPopup.dataset.nationId && !targetContent.dataset.fetched) {
                await fetchNationEconomyData(nationInfoPopup.dataset.nationId);
                targetContent.dataset.fetched = 'true'; // Mark data as fetched
            }
        }
    });
});

async function fetchNationEconomyData(nationId) {
	const { data: { session } } = await supabase.auth.getSession();
	const accessToken = session.access_token;

	console.log("Fetching nation economy data for nation ID:", nationId);

    try {
        const response = await fetch('/api/get_income', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({ type: 'nation', id: nationId })
        });
        const data = await response.json();
        if (data.income) {
			console.log(data.income);
            populateEconomyTab(data.income);
        } else {
            console.error("Error fetching nation income:", data.error);
            // Handle error appropriately (e.g., display a message)
        }
    } catch (error) {
        console.error("Error fetching nation income:", error);
        // Handle error appropriately
    }
}

function populateEconomyTab(income) {
    nationEconomyMoneyProvinces.textContent = income.moneyIncome.fromProvinces;
    nationEconomyMoneyLoyalSettlements.textContent = income.moneyIncome.fromLoyalSettlements;
    nationEconomyMoneyMaintenance.textContent = '-' + income.moneyExpenses.armyMaintenance;
    nationEconomyMoneyNet.textContent = income.netMoneyIncome;
    nationEconomyManpowerProvinces.textContent = income.manpowerIncome.fromProvinces;
    nationEconomyManpowerLoyalSettlements.textContent = income.manpowerIncome.fromLoyalSettlements;
    nationEconomyManpowerNet.textContent = income.netManpowerIncome;
}

btnChangeNationName.addEventListener('click', async function () {
  if (!isEditingNationName) {
    // Switch to edit mode
    isEditingNationName = true;

    // Replace the <h3> with an <input>
    const nationNameElement = document.getElementById('nation-info-popup-title');
    const nationName = nationNameElement.textContent;

    const input = document.createElement('input');
    input.type = 'text';
    input.id = 'nation-name-input';
    input.value = nationName;
    input.style.fontSize = '24px';
    input.style.textAlign = 'center';
    input.style.paddingBottom = '10px';
    input.style.marginBottom = '10px';
    input.style.width = '70%';

    nationNameElement.parentNode.replaceChild(input, nationNameElement);

    // Change the icon to a check icon
    const iconImg = btnChangeNationName.querySelector('img');
    iconImg.src = 'check_icon.png';

  } else {
    // Save the changes
    isEditingNationName = false;

    // Get the new nation name
    const input = document.getElementById('nation-name-input');
    const newNationName = input.value.trim();

    // Validate the new name
    if (newNationName.length === 0) {
      alert('Nation name cannot be empty.');
      return;
    }

    const { data: { session } } = await supabase.auth.getSession();
    const accessToken = session.access_token;

    // Send API request to update the nation name
    try {
      const response = await fetch('/api/update_nation_name', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          nation_id: nationInfoPopup.dataset.nationId,
          nation_name: newNationName,
        }),
      });

      const data = await response.json();
      if (data.success) {
        // Replace the input with a new h3 using the correct ID
        const h3 = document.createElement('h3');
        h3.id = 'nation-info-popup-title';
        h3.textContent = newNationName;
        input.parentNode.replaceChild(h3, input);

        // Change the icon back to the pencil icon
        const iconImg = btnChangeNationName.querySelector('img');
        iconImg.src = 'pencil_icon.png';

        window.map.loadProvinces();
      } else {
        alert('Error updating nation name: ' + data.error);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An unexpected error occurred.');
    }
  }
});

export function openNationInfoPopup(nation) {
    // Reset editing state when opening popup
    isEditingNationName = false;
    const iconImg = btnChangeNationName.querySelector('img');
    iconImg.src = 'pencil_icon.png';
    
    nationInfoPopup.dataset.nationId = nation.id;
    
    // Ensure we're displaying an h3 with the correct ID
    let titleElement = document.getElementById('nation-info-popup-title');
    if (!titleElement || titleElement.tagName.toLowerCase() !== 'h3') {
        // If the element doesn't exist or isn't an h3, create a new one
        titleElement = document.createElement('h3');
        titleElement.id = 'nation-info-popup-title';
        const oldElement = document.querySelector('#nation-name-input, #nation-info-popup-title');
        if (oldElement) {
            oldElement.parentNode.replaceChild(titleElement, oldElement);
        }
    }
    titleElement.textContent = nation.name;
    
    nationInfoRuler.textContent = nation.ruler;
    nationInfoProvinces.textContent = nation.provinces;

    // Initially activate the 'Information' tab
    infoPopupTabs.forEach(tab => tab.classList.remove('active'));
    infoPopupTabContents.forEach(content => content.classList.remove('active'));
    document.querySelector('.info-popup-tab[data-tab="nation-info-details"]').classList.add('active');
    const initialContent = document.getElementById('nation-info-details');
    if (initialContent) {
        initialContent.classList.add('active');
    }

    // Reset the fetched state of the economy tab content
    const economyContent = document.getElementById('nation-info-economy');
    if (economyContent) {
        economyContent.dataset.fetched = '';
    }

    nationInfoPopup.classList.remove('hidden');
}

export function closeNationInfoPopup() {
    nationInfoPopup.classList.add('hidden');
}