import { toLonLat } from 'ol/proj.js';
import { supabase } from '../../supabaseClient.js';
import { loadOutliner } from '../../ui/outliner.js';


export function promptFirstSettlementPlacement(mapObj) {
    // Show our new popup
    const popup = document.getElementById('tutorial-popup');
    const button = document.getElementById('tutorial-popup-button');
    popup.classList.remove('hidden');

    button.addEventListener('click', () => {
        popup.classList.add('hidden');
        mapObj.settlementPlacementMode = true;
        // Create an image element to follow the cursor
        mapObj.cursorImage = document.createElement('img');
        mapObj.cursorImage.src = 'dot.png';
        mapObj.cursorImage.style.position = 'absolute';
        mapObj.cursorImage.style.pointerEvents = 'none';
        mapObj.cursorImage.style.width = '32px';
        mapObj.cursorImage.style.height = '32px';
        document.body.appendChild(mapObj.cursorImage);

        // Bind the event handlers
        mapObj.settlementPlacementHandler =handleSettlementPlacement.bind(mapObj);
        mapObj.updateCursorImagePositionHandler = updateCursorImagePosition.bind(mapObj);

        mapObj.map.on('click', mapObj.settlementPlacementHandler);
        mapObj.map.getViewport().addEventListener('pointermove', mapObj.updateCursorImagePositionHandler);
    });
  }

function updateCursorImagePosition(evt) {
    const mapObj = this;
    if (mapObj.settlementPlacementMode && mapObj.cursorImage) {
        // Get mouse position
        const mouseX = evt.clientX;
        const mouseY = evt.clientY;

        // Update image position
        mapObj.cursorImage.style.left = mouseX - 16 + 'px';
        mapObj.cursorImage.style.top = mouseY - 16 + 'px';
    }
  }

export async function handleSettlementPlacement(evt) {
    const mapObj = this;
    if (mapObj.settlementPlacementMode) {
        const coordinate = evt.coordinate;
        const lonLat = toLonLat(coordinate);

        // Get the province at the clicked location
        const pixel = mapObj.map.getEventPixel(evt.originalEvent);
        const feature = mapObj.map.forEachFeatureAtPixel(pixel, function (feature) {
            return feature;
        });

        if (feature && mapObj.featureInLayer(feature, mapObj.provincesLayer)) {
            const province_id = feature.get('id');

            // Remove the cursor image and event listeners
            document.body.removeChild(mapObj.cursorImage);
            mapObj.cursorImage = null;
            mapObj.map.un('click', mapObj.settlementPlacementHandler);
            mapObj.map.getViewport().removeEventListener('pointermove', mapObj.updateCursorImagePositionHandler);
            mapObj.settlementPlacementMode = false;


            const {
                data: { session },
            } = await supabase.auth.getSession();
            const accessToken = session.access_token;

            // Call the API to place the settlement
            fetch('/api/place_settlement', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                user_id: window.currentUser.id,
                coords: lonLat.join(','),
                name: window.currentUser.user_metadata.settlement_name,
                province_id: province_id,
            }),
            })
            .then((response) => response.json())
            .then((data) => {
                // Handle success
                console.log('Settlement placed:', data);

                mapObj.loadSettlements(window.currentUser.id).then(async () => {
                    await mapObj.reLoadData();
                    const settlement_id = Object.keys(window.map.settlements).find(key => window.map.settlements[key].user_id === window.currentUser.id);
                    mapObj.focusSettlement(settlement_id);
                });
            })
            .catch((error) => {
                console.error('Error placing settlement:', error);
            });
        } else {
            alert('Please click on a province to place your settlement.');
        }
    }
  }