// /src/js/ui/chat.js
import { createClient } from '@supabase/supabase-js';

// Replace these with your own:
import { ANON_KEY, ANON_URL, DEV_ANON_KEY, DEV_ANON_URL } from '../../../public/keys.js';

var supabaseUrl = '';
var supabaseAnonKey = '';

if (window.location.href.indexOf("fief") > -1) {
  supabaseUrl = ANON_URL;
  supabaseAnonKey = ANON_KEY;
}
else {
  supabaseUrl = DEV_ANON_URL;
  supabaseAnonKey = DEV_ANON_KEY;
}

// Create the Supabase client
const supabase = createClient(supabaseUrl, supabaseAnonKey);


// The ID of the user (if you have logic for logged in user, supply it).
// Or you might fetch user info from local storage, etc.
let currentUserId = null;
let displayName = null;

// Listen to your messages table for new inserts
async function subscribeToMessages() {
  // If your table is named 'messages', adapt as needed
  supabase
    .channel('realtime-messages-channel')
    .on(
      'postgres_changes',
      { event: 'INSERT', schema: 'public', table: 'messages' },
      (payload) => {
        const newMessage = payload.new;
        appendMessageToChat(newMessage);
      }
    )
    .subscribe();
}

// Fetch the last N messages from your "messages" table on load
async function fetchRecentMessages() {
  // You can fetch 50 most recent messages or however many you like
  const { data, error } = await supabase
    .from('messages')
    .select('*')
    .order('created_at', { ascending: false })
    .limit(50);

  if (error) {
    console.error('Error fetching messages', error);
    return;
  }

  // Because we ordered descending, we might want to reverse them
  // so the earliest is at the top
  const ordered = data.reverse();
  ordered.forEach((msg) => appendMessageToChat(msg));
}

// Insert a new message
async function sendMessage(content) {
  if (!content) return;
  if (!currentUserId) {
    // If you do not have an auth setup, remove this check or set a placeholder.
    console.warn('User is not logged in. Provide a user ID or handle anonymous messages here.');
    return;
  }

  // Insert into the "messages" table
  const { data, error } = await supabase
    .from('messages')
    .insert([{ user_id: currentUserId, display_name: displayName, content }])
    .select();
  if (error) {
    console.error('Error inserting message', error);
  }
}

function clearChatMessages() {
	const chatMessagesEl = document.getElementById('chat-messages');
	if (chatMessagesEl) {
		chatMessagesEl.innerHTML = '';
	}
}

// This function appends messages to the chat container
function appendMessageToChat({ user_id, display_name, content, inserted_at }) {
  const chatMessagesEl = document.getElementById('chat-messages');
  if (!chatMessagesEl) return;

  const messageEl = document.createElement('div');
  messageEl.classList.add('chat-message');

  // Format username or user_id
  // You could do something more advanced here (e.g., fetch username by ID, etc.)
  const userLabel =  `${display_name}`;

  messageEl.innerHTML = `
    <span class="chat-message-user">${userLabel}:</span>
    <span class="chat-message-content">${content}</span>
  `;

  chatMessagesEl.appendChild(messageEl);
  // Optionally scroll to bottom
  chatMessagesEl.scrollTop = chatMessagesEl.scrollHeight;
}

// Setup event listeners for sending messages and toggling chat
function initChatUI() {
  var chatContainer = document.getElementById('chat-container');
  var toggleBtn = document.getElementById('chat-toggle-btn');
  var sendBtn = document.getElementById('chat-send-btn');
  var chatInput = document.getElementById('chat-input');
  var openChatBtn = document.getElementById('open-chat-btn');
  var chatMessagesEl = document.getElementById('chat-messages');

  if (window.currentUser.id) {
	  chatContainer.classList.add('hidden');
	  openChatBtn.classList.remove('hidden');
  }

  //remove existing event listeners
  toggleBtn.removeEventListener('click', () => {});
  sendBtn.removeEventListener('click', () => {});
  chatInput.removeEventListener('keyup', () => {});
  openChatBtn.removeEventListener('click', () => {});

  // Toggle show/hide
  toggleBtn.addEventListener('click', () => {
    // e.g., toggle a class that collapses the chat
	console.log('toggle chat');
    chatContainer.classList.add('hidden');
    // Optionally change the button text from "−" to "+"
    toggleBtn.textContent = '−';

	openChatBtn.classList.remove('hidden');
  });

  // Send messages
  sendBtn.addEventListener('click', () => {
    const content = chatInput.value.trim();
    if (!content) return;
    sendMessage(content);
    chatInput.value = '';
  });

  openChatBtn.addEventListener('click', () => {
	chatContainer.classList.remove('hidden');
	openChatBtn.classList.add('hidden');
	chatMessagesEl.scrollTop = chatMessagesEl.scrollHeight;
  });
  

  // Send on "Enter" press
  chatInput.addEventListener('keyup', (e) => {
    if (e.key === 'Enter') {
      sendBtn.click();
    }
  });


}

// Initialization function
export async function initChat() {
  clearChatMessages();
  // If you have logic to get the current user, do that here:
  // currentUserId = getAuthenticatedUserIdSomehow();
  // For the example, let's just do a random ID or a forced ID:
  currentUserId = await window.currentUser.id;
  displayName = await window.currentUser.user_metadata.display_name;

  initChatUI();
  await fetchRecentMessages();
  await subscribeToMessages();
}

